import type { LocationQueryRaw } from '#vue-router';
import { useMainStore } from '~/stores/mainStore';
import { FormStatus } from '@utilities/types';

export const useHelpers = () => {
  function updateRouteQueryParams({
    query,
    options = {},
    scroll = true,
  }: {
    query: LocationQueryRaw;
    options?: object;
    scroll?: boolean;
  }): void {
    const router = useRouter();

    if (scroll) {
      const { scrollToElement } = useMainStore();

      scrollToElement(options);
    }

    router.push({
      path: router.currentRoute.value.path,
      query,
    });
  }

  const units = [
    'bytes',
    'KiB',
    'MiB',
    'GiB',
    'TiB',
    'PiB',
    'EiB',
    'ZiB',
    'YiB',
  ];

  const formStatusesWhereStartDateIsNoLongerAConcern = [
    FormStatus.ACTIVE,
    FormStatus.APPROVED,
    FormStatus.CANCELLED,
    FormStatus.CLOSED,
    FormStatus.DECLINED,
    FormStatus.PENDING,
    FormStatus.PROCESSING,
    FormStatus.VOID,
  ];

  function formatBytes(x: any): string {
    let l = 0;
    let n = parseInt(x, 10) || 0;
    while (n >= 1024 && ++l) {
      n = n / 1024;
    }
    return n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l];
  }

  function formatPrice(value: number): String {
    const strValue = String(value);
    const nums = strValue.split('.');

    // e.g: 99 or 0.99
    if (nums.length === 1 || nums[1].length > 1) return strValue;

    // e.g: 9.9
    return `${strValue}0`;
  }

  function cdnAsset(value: string): string {
    const runtimeConfig = useRuntimeConfig();
    return `${runtimeConfig.public.cdnUrl}/${value}`;
  }

  function sumInt(array: number[]): number {
    return array.reduce((accumulator: number, total: number) => {
      return accumulator + total;
    }, 0);
  }

  function ucFirst(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  function calculatePercentage(current: number, max: number, min: number = 0) {
    return Math.ceil((((current - min) / (max - min)) * 100).toFixed(2));
  }

  return {
    formStatusesWhereStartDateIsNoLongerAConcern,
    updateRouteQueryParams,
    formatBytes,
    formatPrice,
    cdnAsset,
    sumInt,
    ucFirst,
    calculatePercentage,
  };
};
