import { shallowRef } from 'vue';
import { ToastType } from 'flowbite-vue/dist/components/Toast/types';
import { ToastItem } from 'flowbite-vue/dist/components/Toast/components/ToastProvider/types';
import UhToast from '~/components/notifications/uhToasts/UhToast.vue';
import { useToasterStore } from '~/components/notifications/uhToasts/uhToastProvider/store';

export type UseToaster = {
  add: (
    type: ToastType,
    message: string,
    time?: number,
    title?: string,
    id?: string,
  ) => string;
  addCustom: (toast: ToastItem) => string;
  remove: (id: string) => boolean;
  pop: () => string;
  handleErrors: (error: object, errorMessageFallback?: string) => void;
  handleVuelidateErrors: (v$: object) => void;
  getLength: () => number;
};

export const useToaster = (): UseToaster => {
  const store = useToasterStore();

  const addCustom = (toast: ToastItem): string => {
    return store.addToast(toast);
  };

  const add = (
    type: ToastType,
    message: string,
    time?: number,
    title?: string,
    id?: string,
  ) => {
    const toastId =
      id ||
      parseInt((new Date().getTime() * Math.random()).toString()).toString();
    return store.addToast({
      id: toastId,
      type,
      text: message,
      time: time || 10 * 1000,
      title: title || null,
      component: shallowRef(UhToast),
      componentProps: {
        closable: true,
        type,
        divide: type !== 'empty' && type !== 'danger',
        title,
      },
    });
  };
  const remove = (id: string): boolean => {
    return store.removeToast(id);
  };
  const pop = (): string => {
    return store.popToast();
  };

  const handleErrors = (error: object, errorMessageFallback?: string): void => {
    let showFallback: boolean = true;

    // Do we have some validation errors?
    if (error?.value?.data?.errors) {
      const errors = error.value.data.errors;
      const keys = Object.keys(errors);
      // Create a toast message for each error
      keys.forEach((key) => {
        add('danger', errors[key]);
      });

      showFallback = false;
    }

    // Do we have an error from the data?
    if (error?.value?.data?.error) {
      add('danger', error.value.data.error);
      showFallback = false;
    }

    if (error?.value?.data?.exception) {
      const errorData = error.value.data;
      const errorMessage: string = `An unexpected server error occurred: ${errorData.exception}: ${errorData.message}`;

      const fileName: string = errorData.file.split('/').pop();
      const location: string = `${errorData.file.replace(
        '/var/www/html/',
        '',
      )}:${errorData.line}`;

      console.error(
        `${errorData.exception}: ${errorData.message} on line ${errorData.line} of ${fileName}: ${location}`,
      );
      add('danger', errorMessage);
      showFallback = false;
    }

    if (showFallback && errorMessageFallback) {
      add('danger', errorMessageFallback);
    }
  };

  // display any vuelidate errors
  const handleVuelidateErrors = (v$: object) => {
    if (v$?.value?.$errors) {
      const errors = v$.value.$errors;

      if (errors.length === 0) {
        return;
      }

      for (let i = 0; i < errors.length; i++) {
        const message: string = errors[i].$message.trim();
        if (message !== '') {
          add('danger', message as string);
        }
      }
    }
  };

  const getLength = (): number => {
    return store.getLength();
  };

  return {
    addCustom,
    add,
    remove,
    pop,
    handleErrors,
    handleVuelidateErrors,
    getLength,
  };
};
