<script lang="ts">
import { defineComponent, h, TransitionGroup } from 'vue';
import type { PropType } from 'vue';
import { Toast } from 'flowbite-vue';
import type { ToastTransition } from 'flowbite-vue/dist/components/Toast/components/ToastProvider/types';
import { useToasterStore } from '~/components/notifications/uhToasts/uhToastProvider/store';

export default defineComponent({
  components: {
    Toast: Toast as any,
  },
  props: {
    transition: {
      type: String as PropType<ToastTransition>,
      default: 'slide-left',
    },
    position: {
      type: String as PropType<
        'top-left' | 'top-right' | 'bottom-left' | 'bottom-right'
      >,
      default: 'top-right',
    },
  },
  setup() {
    const store = useToasterStore();

    return {
      toasts: store.toasts,
      removeToast: store.removeToast,
    };
  },
  render() {
    const { $props, $slots, toasts, removeToast } = this;

    let positionClasses = '';

    switch ($props.position) {
      case 'top-left':
        positionClasses = 'top-20 left-3';
        break;
      case 'top-right':
        positionClasses = 'top-20 right-4';
        break;
      case 'bottom-left':
        positionClasses = 'bottom-3 left-3';
        break;
      case 'bottom-right':
        positionClasses = 'bottom-3 right-4';
        break;
      default:
        positionClasses = 'top-20 right-4';
        break;
    }

    return h('div', {}, [
      $slots.default ? $slots.default() : null, // rendering default slot
      h(
        TransitionGroup,
        {
          name: $props.transition,
          tag: 'div',
          class:
            'xl:w-1/5 md:w-1/4 sm:w-1/4 fixed flex flex-col gap-2 z-[100] min-w-60 max-w-xs lg:max-w-sm ' +
            positionClasses,
        },
        {
          default: () =>
            toasts.map(
              (
                _toast, // rendering every toast
              ) =>
                _toast.component
                  ? h(
                      _toast.component,
                      {
                        key: _toast.id,
                        onClose: () => removeToast(_toast.id),
                        ...(_toast.componentProps ? _toast.componentProps : {}),
                      },
                      () => _toast.text,
                    )
                  : h(
                      Toast as any,
                      {
                        closable: true,
                        type: _toast.type,
                        key: _toast.id,
                        onClose: () => removeToast(_toast.id),
                      },
                      () => _toast.text,
                    ),
            ),
        },
      ),
    ]);
  },
});
</script>

<style scoped lang="scss">
.slide-left-enter-active,
.slide-left-leave-active {
  transition: all 0.5s ease;
}
.slide-left-enter-from,
.slide-left-leave-to {
  opacity: 0;
  transform: translateX(30px);
}

.slide-right-enter-active,
.slide-right-leave-active {
  transition: all 0.5s ease;
}
.slide-right-enter-from,
.slide-right-leave-to {
  opacity: 0;
  transform: translateX(-30px);
}

.slide-top-enter-active,
.slide-top-leave-active {
  transition: all 0.5s ease;
}
.slide-top-enter-from,
.slide-top-leave-to {
  opacity: 0;
  transform: translateY(30px);
}

.slide-bottom-enter-active,
.slide-bottom-leave-active {
  transition: all 0.5s ease;
}
.slide-bottom-enter-from,
.slide-bottom-leave-to {
  opacity: 0;
  transform: translateY(-30px);
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
